import styled from 'styled-components'

export const ProfilePhoto = styled.img`
 max-width: 150px;
 border-radius: 20%;
`

export const MainContainer = styled.div`
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  margin-bottom: 2em;

  @media only screen and (max-width: 1100px) {
    height: 100%;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 14em;
    padding: 0em 2em;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .btn {
    margin-bottom: 1.4em;
    padding: 0.8em 1.6em;
    background: #ffff3e;
    text-transform: uppercase;
    border-radius: 3.2em;
    font-weight: 800;
    font-size: 18px;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const WelcomeText = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 2.6em;

  @media only screen and (max-width: 600px) {
    margin-bottom: 4.6em;
  }
`

export const HeroText = styled.div`
  font-size: 88px;
  font-weight: 700;
  line-height: 95%;
  color: #000;
  text-align: center;
  margin-bottom: 0.4em;
  white-space: pre-line;
  z-index: 5;

  @media only screen and (max-width: 1600px) {
    font-size: 7.6em;
  }

  @media only screen and (max-width: 920px) {
    white-space: normal;
  }

  @media only screen and (max-width: 600px) {
    font-size: 4.8em;
    line-height: 105%;
    white-space: normal;
  }
`

export const SecondaryText = styled.div`
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  color: #000;
  margin-bottom: 1.8em;
  white-space: pre;

  @media only screen and (max-width: 1600px) {
    font-size: 2.4em;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2em;
    line-height: 105%;
    white-space: normal;
  }
`

export const BuzzCoin = styled.img`
  position: absolute;
  top: 0;
  left: 6%;
  width: 13%;

  @media only screen and (max-width: 600px) {
    width: 40%;
    top: 0px;
    left: -30px;
  }
`

export const LeftArrow = styled.img`
  position: absolute;
  left: -5%;
  width: 10%;

  @media only screen and (max-width: 600px) {
    width: 38%;
    top: 200px;
    left: -120px;
  }
`

export const LeftNFT = styled.img`
  position: absolute;
  left: 7%;
  bottom: 8%;
  width: 9%;

  @media only screen and (max-width: 600px) {
    width: 23%;
    bottom: 100px;
    left: -70px;
  }
`

export const BuzzCoinRight = styled.img`
  position: absolute;
  right: -5%;
  width: 13%;
  transform: rotate(270deg);

  @media only screen and (max-width: 600px) {
    width: 38%;
    top: 120px;
    right: -105px;
  }
`

export const RightArrow = styled.img`
  position: absolute;
  right: 0%;
  width: 10%;
  bottom: 5%;

  @media only screen and (max-width: 600px) {
    width: 32%;
    bottom: 145px;
    right: -80px;
    rotate: 50deg;
  }
`

export const RightNFT = styled.img`
  position: absolute;
  top: 6%;
  right: 7%;
  width: 9%;

  @media only screen and (max-width: 600px) {
    width: 23%;
    top: 65px;
    right: 0px;
  }
`

export const LinePattern = styled.img`
  position: absolute;
  bottom: 0;
  z-index: -1;
`
