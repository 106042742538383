import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  gap: 2em;
  margin-bottom: 3em;
  justify-content: center;

  .name-error,
  .email-error {
    border: 2px solid #f2059f;
  }

  .btn {
    border: 2px solid #000;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      150ms cubic-bezier(0, 0, 0.58, 1);

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #000;
      border: 1px solid #000;
      border-radius: 4em;
      transform: translate3d(0, 0.7em, -1em);
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:hover {
      background: #ffff3e;
      transform: translate(0, 0.25em);
      &::before {
        transform: translate3d(0, 0.5em, -1em);
      }
    }
    &:active {
      background: #ffff3e;
      transform: translate(0em, 0.75em);
      &::before {
        transform: translate3d(0, 0, -1em);
      }
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
export const StyledInput = styled.input`
  width: 35em;
  border: 2px solid #000;
  padding: 1.4em 2em;
  border-radius: 3.2em;
  font-size: 16px;
  font-weight: 600;
  transform: translate3d(0, -6px, 0);
  outline: none;

  &::placeholder {
    color: #696367;
    font-weight: 400;
    font-size: 20px;
    vertical-align: middle;
    line-height: 100%;
    transform: translate3d(0, 2px, 0);

    @media only screen and (max-width: 1600px) {
      font-size: 1.4em;
    }
  }

  @media only screen and (max-width: 1600px) {
    font-size: 1.5em;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;

  .join-btn {
    border: 2px solid #000;
    transform-style: preserve-3d;
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      150ms cubic-bezier(0, 0, 0.58, 1);

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #000;
      border: 1px solid #000;
      border-radius: 4em;
      transform: translate3d(0, 0.7em, -1em);
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1);
    }
    &:hover {
      background: #ffff3e;
      transform: translate(0, 0.25em);
      &::before {
        transform: translate3d(0, 0.5em, -1em);
      }
    }
    &:active {
      background: #ffff3e;
      transform: translate(0em, 0.75em);
      &::before {
        transform: translate3d(0, 0, -1em);
      }
    }
  }
`

export const JoinBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffff3e;
  font-size: 1.6em;
  font-weight: 900;
  color: black;
  border-radius: 3.2em;
  border: 2px solid #000;
  padding: 1em 2em;
  text-transform: uppercase;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const DisclaimerText = styled.div`
  font-size: 10px;
  text-align: center;
  color: #474345;
  width: 60%;
  white-space: pre-line;

  @media only screen and (max-width: 600px) {
    width: 100%;
    white-space: normal;
  }
`

export const ErrorMessages = styled.div`
  display: flex;
  width: 40em;
  color: #f2059f;
  font-size: 1.2em;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`

export const OuterLayer = styled.div`
  background-color: #000;
  border-radius: 3.2em;
  margin-bottom: 0.2em;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
