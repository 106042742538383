import { graphql } from 'gatsby'
import React, { useState } from 'react'

import Layout from '../components/Layout/Layout'
import {
  DisclaimerText,
  ErrorMessages,
  Form,
  JoinBtn,
  MainContainer as InputMainContainer,
  OuterLayer,
  StyledInput,
} from '../fragments/invited/Hero/Input/style'
import { linePattern } from '../images/invited/hero'
import {
  buzzCoin,
  leftArrow,
  rightArrow,
  leftNFT,
  rightNFT,
} from '../images/waitlist/hero'
import wave from '../images/waitlist/wave.svg'
import {
  BuzzCoin,
  BuzzCoinRight,
  HeaderContainer,
  LeftArrow,
  LeftNFT,
  LinePattern,
  MainContainer,
  RightArrow,
  RightNFT,
  WelcomeText,
} from '../fragments/invited/Hero/styles'
import { useTranslation } from 'react-i18next'
import { HeroText } from '../fragments/unicorn/styles'
import { SecondaryText } from '../fragments/unicorn/styles'

const PitchDeck = () => {
  const { t } = useTranslation(['pitch'])

  const [email, setEmail] = useState('')
  const [showPitch, setShowPitch] = useState(false)
  const [emailError, setEmailError] = useState('')

  const validateEmail = mail => {
    if (/^\S+@\S+\.\S+$/.test(mail)) {
      return true
    }
    return false
  }

  const validate = () => {
    if (validateEmail(email) === false) {
      setEmailError('Invalid email')
      return false
    }

    return true
  }

  const handleForm = async event => {
    event.preventDefault()

    if (!validate()) {
      return false
    }

    const myForm = event.target
    const formData = new FormData(myForm)

    console.log(formData)

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setEmail('')
        setEmailError('')
        window.location.href =
          'https://www.figma.com/proto/1DISYIjruotxsYjy6yuxD7/Buzz_Presentation?node-id=1747-12210&scaling=scale-down'
        // setShowPitch(true)
      })
      .catch(error => alert(error))
  }

  const yourEmailPlaceholder = t('yourEmail')

  return showPitch ? (
    <iframe
      width="100%"
      height="100%"
      src="https://www.figma.com/proto/1DISYIjruotxsYjy6yuxD7/Buzz_Presentation?node-id=1747-12210&scaling=scale-down"
      style={{ height: '100vh', width: '100vw' }}
      allowFullScreen
    ></iframe>
  ) : (
    <Layout paddingTop={50} isWaitlist>
      <MainContainer>
        <BuzzCoin src={buzzCoin} />
        <LeftArrow src={leftArrow} />
        <LeftNFT src={leftNFT} />
        <BuzzCoinRight src={buzzCoin} />
        <RightArrow src={rightArrow} />
        <RightNFT src={rightNFT} />
        <HeaderContainer>
          <WelcomeText>
            {t('invited')} <img src={wave} />
          </WelcomeText>
          <HeroText>{t('heroText')}</HeroText>
          <SecondaryText>{t('secondaryText')}</SecondaryText>

          <InputMainContainer>
            <Form
              name="pitch"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleForm}
            >
              <input type="hidden" name="form-name" value="pitch" />
              <input type="hidden" name="subject" value="Pitch deck form" />
              <OuterLayer>
                <StyledInput
                  type="email"
                  name="email"
                  placeholder={yourEmailPlaceholder}
                  value={email}
                  className={emailError ? 'email-error' : ''}
                  onChange={e => setEmail(e.target.value)}
                  onFocus={() => setEmailError('')}
                ></StyledInput>
              </OuterLayer>
              {emailError ? <ErrorMessages>{emailError}</ErrorMessages> : null}
              <DisclaimerText>{t('disclaimerText')}</DisclaimerText>
              <JoinBtn type="submit" className="join-btn">
                {t('submit')}
              </JoinBtn>
            </Form>
          </InputMainContainer>
        </HeaderContainer>
        <LinePattern src={linePattern} />
      </MainContainer>
    </Layout>
  )
}

export default PitchDeck

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
