import styled from 'styled-components'

export const HeroText = styled.div`
  font-size: 88px;
  font-weight: 700;
  line-height: 95%;
  color: #000;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 0.4em;
  z-index: 5;

  @media only screen and (max-width: 1600px) {
    font-size: 7.6em;
  }

  @media only screen and (max-width: 600px) {
    font-size: 4.8em;
    line-height: 105%;
    white-space: pre-wrap;
  }
`

export const SecondaryText = styled.div`
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  color: #000;
  margin-bottom: 1.8em;

  @media only screen and (max-width: 1600px) {
    font-size: 2.4em;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2em;
    line-height: 105%;
    white-space: pre;
  }
`
